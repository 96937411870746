import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";
import { useSignedIn } from ".";
import { uniqueNamesGenerator, adjectives, colors, animals, starWars } from 'unique-names-generator';
import { changeGuestIdentity, createGuestIdentity as createGuestIdentityApi, clearGuestIdentity as clearGuestIdentityApi } from "modules/yoio/api/accessApi";
import { useDebounced } from "modules/picasso-ui/form/useDebounced";
import { isCookiesSupported, isLocalStorageSupported } from "utils/featureDetects";
import { getFromLocalStorageById, removeFromLocalStorage, saveInLocalStorage } from "modules/yoio-modules/storage/clientSideStore/clientSideStore";
import { notify } from "modules/yoio/errorsService";
import { randomNumber } from "utils/randomUtils";
import { GUEST_IDENTITY_STORAGE_KEY } from "./guestIdentityClientService";

const namePrefixes = [adjectives, colors]
const nameSubjects = [starWars, animals]

const getCustomNameConfig = (namePrefixesDict, nameSubjectsDict) => {
  return {
    dictionaries: [namePrefixes[namePrefixesDict], nameSubjects[nameSubjectsDict]],
    separator: '-',
    length: 2,
    style: 'capital',
  }
}

/**
 * This is the 'current' version, not the 'old' (renaming happened to fast).
 */
export const useGuestIdentityOld = () => {

    const { me } = useSignedIn()

    const { handleDebounced } = useDebounced()

    const hasGuestIdentity = () => {
      return isHavingValue(me.accessContext.guestIdentity)
    }

    const hasGuestIdentityWithName = () => {
      return isHavingValue(me.accessContext.guestIdentity) && isHavingValue(me.accessContext.guestIdentity.name)
    }

    const hasGuestIdentitySigned = () => {
      return isHavingValue(me.accessContext.guestIdentity) && me.accessContext.guestIdentity.type === 'SERVERSIDE_SIGNED'
    }

    const getName = () => {
        return me.accessContext.guestIdentity?.name;
    }

    const getUserId = () => {
      return me.accessContext.guestIdentity?.guestId;
    }

    const saveGuestIdentity = (data) => {

      return changeGuestIdentity(data).then(gid=>{
        let guestIdentity = getFromLocalStorageById(GUEST_IDENTITY_STORAGE_KEY);
        if (!guestIdentity) {
          guestIdentity = {
            name: data.name,
            guestId: gid,
          }
          saveInLocalStorage(GUEST_IDENTITY_STORAGE_KEY, guestIdentity)
        } else if (guestIdentity.guestId !== gid) {
          guestIdentity.guestId = gid
          saveInLocalStorage(GUEST_IDENTITY_STORAGE_KEY, guestIdentity)
        }
      })

    }

    const createGuestIdentity = (withRandomName) => {

      return createGuestIdentityApi().then(({token, guestId})=>{
        try {
          notNullNotUndefined(token)
          notNullNotUndefined(guestId)
        } catch (error) {
          notify(new Error('expected token and guestId in response'))
          throw error
        }

        let guestIdentity = getFromLocalStorageById(GUEST_IDENTITY_STORAGE_KEY)
        if (!guestIdentity) {
          guestIdentity = {}
        } 

        guestIdentity.guestId = guestId
        guestIdentity.token = token
        if (withRandomName === true) {
          guestIdentity.name = generateGuestName()
        }
        saveInLocalStorage(GUEST_IDENTITY_STORAGE_KEY, guestIdentity)
        return guestIdentity;
      })
  
    }

    const clearGuestIdentity = () => {
      return clearGuestIdentityApi().then(()=>{
        removeFromLocalStorage(GUEST_IDENTITY_STORAGE_KEY)
        return me.reloadMe()
      })
    }

    const saveGuestIdentityDebounced = handleDebounced(saveGuestIdentity, 400)

    const setName = (value) => {
      if (!value) {
        return Promise.resolve();
      }
      if (value.length <= 1) {
        return Promise.resolve();
      }

      if (!isLocalStorageSupported()) {
        throw new Error('localStorage not supported') //cannot work without (based on current solution)
      }

      let guestIdentity = getFromLocalStorageById(GUEST_IDENTITY_STORAGE_KEY);
      if (guestIdentity) {
        guestIdentity.name = value;
        saveInLocalStorage(GUEST_IDENTITY_STORAGE_KEY, guestIdentity);
      }

      if (isCookiesSupported()) {
        return saveGuestIdentity({
          name: value
        })
      } else {
        const infoMsg = 'info: cookie not supported'
        console.info(infoMsg)
        notify(new Error(infoMsg))

        return saveGuestIdentity({
          name: value,
          setCookieOnResponse: false,
        })
      }

    }

    const generateUniqeNameInternal = (seed) => {
      if (seed) {
        //choose deterministic dictionary combination based on seed
        let firstChar = seed+''.substring(0,1)
        let secondChar = seed+''.substring(1,2)
        let dict1Chooser = parseInt(firstChar, 16) > 8 ? 1 : 0
        let dict2Chooser = parseInt(secondChar, 16) > 8 ? 1 : 0
        return uniqueNamesGenerator({
          ...getCustomNameConfig(dict1Chooser, dict2Chooser),
          seed
        })
      } else {
        //no seed, just random
        return uniqueNamesGenerator(getCustomNameConfig(randomNumber(0,1),randomNumber(0,1)))
      }

    }

    const generateGuestName = (seed) => {
      return generateUniqeNameInternal(seed)
    }

    return { getName, setName, hasGuestIdentity, hasGuestIdentityWithName, hasGuestIdentitySigned, getUserId, generateGuestName, createGuestIdentity, clearGuestIdentity }

}