import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isHavingValue, notNullNotUndefined, orElse } from 'utils/objectUtils';
import { useRouterUtils } from "utils/routerUtils";

export const useQueryParam = (key, defaultValue) => {
    notNullNotUndefined(key)

    const router = useRouter()

    const [value, setValue] = useState()

    useEffect(()=>{
        if (!router.isReady) {
            return;
        }
        const rawValue = router.query[key]
        if (rawValue) {
            if (rawValue === 'true') {
                setValue(true)
            } else if (rawValue === 'false') {
                setValue(false)
            } else {
                setValue(rawValue)
            }
        } else if (defaultValue) {
            setValue(defaultValue)
        }
    },[router.isReady, router.query])

    return {
        value
    }
}

/**
 * 
 * @param {*} items 
 *      Array of objects
 * @returns 
 *     { index, data }
 * 
 *      Index and Data of the object that matches to the query parameter
 *  
 * 
 */
export const useQueryParameterMapping = (queryParam, items, predicate, defaultIndex, options) => {
    notNullNotUndefined(queryParam)
    notNullNotUndefined(predicate)

    const { queryParamEnabled } = options || {}

    queryParamEnabled = orElse(queryParamEnabled, true)

    const router = useRouter()
    const routerUtils = useRouterUtils()

    const initialValue = {ready: false}
    if (isHavingValue(defaultIndex)) {
        initialValue.index = defaultIndex
    }
    const [value, setValue] = useState(initialValue)

    const isMounted = useIsMounted()
    
    useEffect(()=>{
        if (!isHavingValue(defaultIndex)) {
            return;
        }

        setIndex(defaultIndex)
      },[defaultIndex])

    useEffect(()=>{
        if (!isMounted()) {
            return;
        }
        if (!router.isReady) {
            return;
        }
        if (!items) {
            setValue({ready: true, index: isHavingValue(defaultIndex) ? defaultIndex : 0 })
            return;
        }

        const query = router.query[queryParam]
        if (queryParamEnabled && query) {
            const targetValue = findTargetValue(query)

            if (isHavingValue(targetValue)) {
                setValue(targetValue)
            }
        } else {
            setValue({ready: true, index: isHavingValue(defaultIndex) ? defaultIndex : 0})
        }
  
      },[router.isReady, router.query[queryParam]])

      const findTargetValue = (query) => {
        let targetValue = null;
        items.forEach((i,idx)=>{
            if (predicate(i, query)) {
                targetValue = {
                    data: i,
                    index: idx,
                    ready: true
                };
            }
        });
        return targetValue;
      }

      const findTargetValueByIndex = (index) => {
        const data = items[index]

        return {
            index,
            data,
            ready: true
        };
      }

    const setIndex = (updatedIndex) => {
        if (!isMounted()) {
            return;
        }

        const targetValue = findTargetValueByIndex(updatedIndex);
        setValue(targetValue)

        if (queryParamEnabled && targetValue.data.key) {
            //insertUrlParam(queryParam, targetValue.data.key)

            routerUtils.pushAdditionalQueryParams({
                [queryParam]: targetValue.data.key
            })
        }
    }

    return { ...value, setIndex }

}