import { notNullNotUndefined } from "utils/objectUtils";

export const notifyWindowEvent = (eventKey: string, value?: any) => {
    notNullNotUndefined(eventKey)

    let event = new Event(eventKey);
    if (value) {
        //@ts-ignore
        event.value = value
    }
    window.dispatchEvent(event)
}

export enum CommonEvents {
   dialogClosed = 'common:dialogClosed',
   settingsDialogClosed = 'common:settingsDialogClosed',
}