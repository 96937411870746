import { getAxiosBackground, getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { filterToQueryParams } from 'utils/apiUtils';
import { getContentTypeOfBase64 } from 'utils/fileUtils';
import { notNullNotUndefined } from 'utils/objectUtils';
import { paramsToQueryString } from 'utils/urlUtils';

export const get = (id, additionalConfig) => 
  getAxiosDefault()({
    url: `/backlogs/${id}`,
    method: 'GET',
    additionalConfig,
  }).then((response) => {
    return response.data;
  });

export const getBacklog = (id, additionalConfig, includeItemsScores, trace) => {
  let params = {}
  if (includeItemsScores) {
    params = params || {}
    params.includeItemsScores = includeItemsScores
  }
  if (trace) {
    params = params || {}
    params.trace = trace
  }
  return getAxiosDefault()({
    url: `/backlogs/${id}`,
    method: 'GET',
    params,
    additionalConfig,
  }).then((response) => {
    return response.data;
  })
}

// options: ApiRequestOptions
export const getEac = (id, options) => 
  getAxiosDefault()({
    url: `/backlogs/${id}/eac`,
    method: 'GET',
    additionalConfig: options
  })

export const getBacklogsOfWorkspaceAsSummaryForTable = () => getAxiosDefault()(`/backlogs/inWorkspace/allAsSummaryForTable?sort=name&size=200`)

export const getBacklogsOfWorkspaceAsAsRoom = () => getAxiosDefault()(`/backlogs/inWorkspace/allAsRoom`)

export const getBacklogsRoomsOfWorkspaceAsSummaryForTable = () => getAxiosDefault()(`/backlogs/inWorkspace/rooms/allAsSummaryForTable?sort=name&size=200`)

export const getBacklogsJiraOfWorkspaceAsSummaryForTable = () => getAxiosDefault()(`/backlogs/inWorkspace/jira/allAsSummaryForTable?sort=name&size=200`)

export const create = (action) => getAxiosDefault().post(`/backlogs/create`, action)

export const createBacklog = (action) => getAxiosDefault().post(`/backlogs/create`, action)

export const createGuestBacklog = (data) => getAxiosDefault().post(`/backlogs/createGuestBacklog`, data)

export const createBacklogJira = (data) => getAxiosDefault().post(`/backlogs/create/jira`, data)

export const createBacklogCopy = (data) => getAxiosDefault().post(`/backlogs/createCopy`, data)

export const createGuestBacklogFromImage = (backlogId, base64) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(base64);

  return fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const fd = new FormData();

      const file = new File([blob], 'asset', {type: getContentTypeOfBase64(base64)});
      fd.append('file', file)

      return getAxiosDefault().post(`/backlogs/${backlogId}/createGuestBacklogFromImage`, fd)
  })

}

export const changeOld = ({ backlogId, data }) => getAxiosDefault().patch(`/backlogs/changeV1/` + backlogId, data);

export const change = (backlogId, data, returnNew) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(data)

  let params = null

  if (returnNew != null && returnNew != undefined) {
    params = params || {}
    params.returnNew = returnNew
  }

  return getAxiosDefault().patch(`/backlogs/${backlogId}`, data, { params })
}

export const changeUserSettingsItemsBlacklistAdd = (backlogId, userId, itemId) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(userId)
  notNullNotUndefined(itemId)

  return change(backlogId, [
      {
          op: 'add',
          path: `userSettings/${userId}/itemsBlacklist/-`,
          value: itemId
      }
  ])
}

export const changeUserSettingsItemsBlacklistRemove = (backlogId, userId, itemId) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(userId)
  notNullNotUndefined(itemId)

  return change(backlogId, [
      {
          op: 'remove',
          path: `userSettings/${userId}/itemsBlacklist/[${itemId}]`,
      }
  ])
}

export const changeUserSettings = ({ backlogId, userId, data }) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(userId)
  notNullNotUndefined(data)
  return getAxiosDefault().post(`/backlogs/${backlogId}/userSettings/${userId}`, data)
}

export const deleteBacklog = ({ backlogId }) =>
  getAxiosDefault().post(`/backlogs/delete`, {
    backlogId,
})


/**
 * @param {*} items
 *    Array of { itemId, name }
 * @returns 
 */
export const itemsCreate = ({ backlogId, items, origin }) =>
  getAxiosDefault().post(`/backlogs/itemsCreate`, {
    backlogId,
    items,
    origin
  })

export const itemDelete = ({ backlogId, itemId }) =>
  getAxiosDefault().post(`/backlogs/itemDelete`, {
    backlogId,
    itemId,
  });

export const itemChangeName = ({ backlogId, itemId, name }) =>
  getAxiosDefault().post(`/backlogs/itemChangeName`, {
    backlogId,
    itemId,
    name,
  });

export const itemChangeDescription = ({ backlogId, itemId, description }) =>
  getAxiosDefault().post(`/backlogs/itemChangeDescription`, {
    backlogId,
    itemId,
    description,
  });

export const itemChangeAssets = ({ backlogId, itemId, assetIds }, requestProps) =>
  getAxiosDefault().post(`/backlogs/itemChangeAssets`, {
    backlogId,
    itemId,
    assetIds,
  }, {...requestProps})

  export const itemChangePortalVisiblity = ({ backlogId, itemId, showInPortalAllowed }) =>
  getAxiosDefault().post(`/backlogs/itemChangePortalVisiblity`, {
    backlogId,
    itemId,
    showInPortalAllowed,
  });

export const itemChangeUrl = ({ backlogId, itemId, url }) =>
  getAxiosDefault().post(`/backlogs/itemChangeUrl`, {
    backlogId,
    itemId,
    url,
  });

export const itemChangeLabelValue = ({ backlogId, itemId, key, value }) =>
  getAxiosDefault().post(`/backlogs/itemChangeLabelValue`, {
    backlogId,
    itemId,
    key,
    value,
  });

export const itemChangeFactors = ({ backlogId, itemId, factors, url }) =>
  getAxiosDefault().post(`/backlogs/itemChangeFactors`, {
    backlogId,
    itemId,
    factors,
    url,
  });

export const itemChangeFactorsSome = (backlogId, itemId, factorId, value) => {
    notNullNotUndefined(backlogId);
    notNullNotUndefined(itemId);
    notNullNotUndefined(factorId);
    notNullNotUndefined(value);

    return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/factors/all`, {
      [factorId] : value
    });
}

export const itemDeleteFactorValue = (backlogId, itemId, factor) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  notNullNotUndefined(factor);

  return getAxiosDefault().delete(`/backlogs/${backlogId}/items/${itemId}/factors/${factor}`);
}

export const getBacklogScoringResult = (backlogId, filter, breakdownDimension, pwcCalculatorVersion) => {
  let params = filterToQueryParams(filter)
  if (breakdownDimension) {
    params = params || {}
    params.breakdownDimension = breakdownDimension
  }
  if (pwcCalculatorVersion) {
    params = params || {}
    params.pwcCalculatorVersion = pwcCalculatorVersion
  }
  return getAxiosDefault().get(`/backlogs/${backlogId}/scoringResult`, { params }).then(res => res.data);
}

export const getBacklogScoringResultsOfWorkspaceUrl = (backlogIds, exportOptions) => {
  let options = {}
  if (backlogIds) {
    options.backlogIds = backlogIds
  } 
  if (exportOptions) {
    options = {
      ...options,
      ...exportOptions,
    }
  }

  const params = filterToQueryParams(options)
  const queryString = paramsToQueryString(params)
  return `/backlogs/inWorkspace/scoringResult/export/export.csv?${queryString}`
}

export const getBacklogScoringResultAsDimensionalMatrix = (backlogId, exportOptions) => {
  let options = {}
  if (exportOptions) {
    options = {
      ...options,
      ...exportOptions,
    }
  }

  const params = filterToQueryParams(options)
  const queryString = paramsToQueryString(params)
  return `/backlogs/${backlogId}/scoringResult/export/dimensionalMatrix.csv?${queryString}`
}

export const getBacklogsAsWorldWideRating = (slugs) => {
  notNullNotUndefined(slugs)
  const slugsValue = slugs.map(i=>encodeURIComponent(i)).join(',')
  return getAxiosDefault().get(`/backlogs/asWorldWideRating?slugs=${slugsValue}`).then(res => res.data);
}

export const analyticsSettingsChange = ({ backlogId, googleAnalyticsSettings }) => {
  const { integrationId, accountId, webpropertyId, profileId } = googleAnalyticsSettings;
  return getAxiosDefault().post(`/backlogs/analyticsSettingsChange`, {
    backlogId,
    googleAnalyticsSettings: {
      integrationId,
      accountId,
      webpropertyId,
      profileId,
    },
  });
};

export const analyticsSettingsDisconnect = ({ backlogId }) => {
  return getAxiosDefault().post(`/backlogs/analyticsSettingsDisconnect`, {
    backlogId,
  });
};

export const projectManagementSettingsChange = ({ backlogId, projectManagementSettings }) => {
  const { integrationId, boardId, importColumn, exportColumn } = projectManagementSettings;
  getAxiosDefault().post(`/backlogs/projectManagementSettingsChange`, {
    backlogId,
    trelloSettings: {
      integrationId,
      boardId,
      importColumn,
      exportColumn,
    },
  });
};


export const scoringSettingsFactorAdd = ({ backlogId, data }) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(data)
  return getAxiosDefault().post(`/backlogs/${backlogId}/scoringSettings/factors`, data)
}

export const scoringSettingsFactorDelete = ({ backlogId, factorId }) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(factorId)
  return getAxiosDefault().delete(`/backlogs/${backlogId}/scoringSettings/factors/${factorId}`)
}

export const scoringSettingsFormulaValidate = ({ backlogId, formula }) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/scoringSettings/formula/validate`, formula, {headers: {'Content-Type': 'text/plain'}})
}

export const getPortal = (backlogId) => {
    return getAxiosDefault().get(`/backlogs/${backlogId}/perspectives/portal`).then(res => res.data);
}

export const getBacklogIdByAlias = (alias, additionalConfig) => {
  notNullNotUndefined(alias)
  return getAxiosDefault()({
    url: `/backlogs/${alias}/id`,
    method: 'GET',
    additionalConfig,
  })
  .then(res => res.data)
}

export const getRoom = (backlogId, params, additionalConfig) => {
  return getAxiosDefault()({
    url: `/backlogs/${backlogId}/perspectives/room`, 
    method: 'GET',
    params,
    additionalConfig,
  })
}

export const getAnsweringPreview = (backlogId, params, additionalConfig) => {
  return getAxiosDefault()({
    url: `/backlogs/${backlogId}/perspectives/answeringPreview`, 
    method: 'GET',
    params,
    additionalConfig,
  })
}

export const getDesignSettings = (backlogId, params, additionalConfig) => {
  return getAxiosDefault()({
    url: `/backlogs/${backlogId}/settings/designSettings`, 
    method: 'GET',
    params,
    additionalConfig,
  })
}

export const getRoomInfo = (backlogId) => {
  return getAxiosDefault().get(`/backlogs/${backlogId}/perspectives/room/info`).then(res => res.data);
}

export const getBacklogRaters = (backlogId) => {
  return getAxiosDefault().get(`/backlogs/${backlogId}/raters`).then(res => res.data);
}

export const getBacklogsPeopleFetchUrl = (backlogId)=>`/backlogs/${backlogId}/people`

export const getBacklogPeople = (backlogId) => {
  return getAxiosDefault().get(getBacklogsPeopleFetchUrl(backlogId)).then(res => res.data);
}

export const getPortalItems = (backlogId) => {
  return getAxiosDefault().get(`/backlogs/${backlogId}/items/inPortal`);
}

export const getFeedbackItemsAsModeratable = (backlogId) => {
  return getAxiosDefault().get(`/backlogs/${backlogId}/items/isFeedbackAsModeratable`);
}

export const feedbackAccept = (backlogId, itemId) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/feedbackAccept`);
}

export const feedbackDecline = (backlogId, itemId) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/feedbackDecline`);
}

export const feedbackReviewOk = (backlogId, itemId) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/feedbackReviewOk`);
}

export const feedbackReviewNotOk = (backlogId, itemId) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/feedbackReviewNotOk`);
}


export const submitFeedbackItem = ({ backlogId, data }) => {
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/submitFeedback`, data);
}

export const submitItemComment = (backlogId, itemId, data) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  notNullNotUndefined(data);
  notNullNotUndefined(data.commentId);
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/comments`, data);
}

export const uploadCommentImageFromBase64 = (backlogId, itemId, commentId, base64, onUploadProgress) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  notNullNotUndefined(commentId);
  notNullNotUndefined(base64);

  return fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const fd = new FormData();

      const file = new File([blob], 'asset', {type: getContentTypeOfBase64(base64)});
      fd.append('file', file)

      return getAxiosBackground().post(`/backlogs/${backlogId}/items/${itemId}/comments/${commentId}/assets`, fd, {
        onUploadProgress
      })
  })

}

export const getItemComments = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().get(`/backlogs/${backlogId}/items/${itemId}/comments?sort=createdAt,desc&page=0&size=100`);
}

export const uploadItemImageFromBase64 = (backlogId, itemId, base64, onUploadProgress, props) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  notNullNotUndefined(base64);

  return fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const fd = new FormData();

      const file = new File([blob], 'asset', {type: getContentTypeOfBase64(base64)});
      fd.append('file', file)

      return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/assets`, fd, {
        onUploadProgress,
        ...props
      })
  })

}

export const itemVoteCreate = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/votes`)
}
export const itemVoteDelete = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().delete(`/backlogs/${backlogId}/items/${itemId}/votes/my`)
}

export const itemUnclearVoteCreateMy = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/unclearVotes/my`)
}
export const itemUnclearVoteDeleteMy = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().delete(`/backlogs/${backlogId}/items/${itemId}/unclearVotes/my`)
}

export const itemUnclearVoteDeleteAll = (backlogId, itemId) => {
  notNullNotUndefined(backlogId);
  notNullNotUndefined(itemId);
  return getAxiosDefault().delete(`/backlogs/${backlogId}/items/${itemId}/unclearVotes`)
}

export const getJiraSettings = (backlogId) => {
  notNullNotUndefined(backlogId);
  return getAxiosDefault().get(`/backlogs/${backlogId}/settings/jira`);
}

export const removeIntegrationJira = (backlogId) => {
  notNullNotUndefined(backlogId);
  return getAxiosDefault().delete(`/backlogs/${backlogId}/settings/jira`);
}

export const getRatingSettings = (backlogId) => {
  notNullNotUndefined(backlogId);
  return getAxiosDefault().get(`/backlogs/${backlogId}/settings/rating`);
}

export const getSharingData = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/sharingData`).then(res => res.data)
}

export const getBacklogType = (backlogId) => getAxiosDefault().get(`/backlogs/${backlogId}/type`)

export const getBacklogInfo = (backlogId) => getAxiosDefault().get(`/backlogs/${backlogId}/info`)

export const getReactionsStats = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/reactionsStats`)
}

export const getScoringSettings = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/scoringSettings`).then(res => res.data)
}

export const getScoringAnsweringFactorsMy = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/answeringScoring/factors/my`)
}

export const getScoringSettingsFactors = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/scoringSettings/factors`)
}
export const getParticipantsSettings = (backlogId) => getAxiosDefault().get(`/backlogs/${backlogId}/settings/participantsSettings`)

export const uploadAssetFromBase64 = (backlogId, base64, props) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(base64)

  return fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const fd = new FormData()

      const file = new File([blob], 'asset', {type: getContentTypeOfBase64(base64)})
      fd.append('file', file)

      return getAxiosDefault().post(`/backlogs/${backlogId}/assets`, fd, {
        ...props
      })
  })

}