
export interface BacklogInfoDto {
    backlogId: string
    name: string
}

export enum BacklogType {
    ROOM = 'ROOM',
    REGULAR = 'REGULAR',
    JIRA = 'JIRA',
}

export interface RankingTemplate {
    templateId: string
    title: string
    description: string
    helperText: string
    thumbnailSrc?: string
    thumbnailRanking?: any
    data: RankingTemplateRankingData
    i18n?: any
    moreUrl?: string
}

export interface RankingTemplateRankingData {
    backlogType: 'ROOM' | 'JIRA'
    factors?: string[]
    items?: string[]
    ratingSettings?: any
}

export type RankingTotalProgressDto = {
    confidenceAvg: number
}

export enum GuestAuthOption {
    unauth = 'unauth',
    google = 'google',
}

export enum RateInput {
    slider = 'slider',
    scale = 'scale',
    buttonsSimple = 'buttonsSimple',
    buttonsOnePerItem = 'buttonsOnePerItem',
}

export const RateInputTexts = {
    [RateInput.scale]: {
        title: 'Preference scale',
        description: 'A participant can choose a preference with intensity.\n\nUsing this, you receive more precise results, and the survey is faster to finish for participants.'
    },
    [RateInput.slider]: {
        title: 'Preference slider',
        description: 'A participant can choose a preference with intensity.\n\nUsing this, you receive more precise results, and the survey is faster to finish for participants.'
    },
    [RateInput.buttonsSimple]: {
        title: 'Click on item',
        description: 'A participant can choose the better choice option by clicking on it.\n\nUsing this, it is easier for participants to answer all questions.'
    }
}

/**
 *  @deprecated only used in tests so far
 */
export enum LayoutVariant {
    standard = 'standard',
    events = 'events',
    images = 'images',
}

export enum RankingDesignType {
    standard = 'standard',
    cozy = 'cozy',
}

export enum ContentType {
    items = 'items',
    images = 'images',
}

export enum ChoiceCardsAlignment {
    center = 'center',
    spaceBetween = 'spaceBetween',
}

export const RankingEvent = {
    rankingItemCreated: 'rankingItemCreated',
    rankingItemChanged: 'rankingItemChanged',
    rankingItemDeleted: 'rankingItemDeleted',
    rankingSettingsChanged: 'rankingSettingsChanged',
    rankingSettingsPairAnswerCapChanged: 'rankingSettingsPairAnswerCapChanged',
    rankingCopyCalled: 'rankingCopyCalled',
    rankingExportCalled: 'rankingExportCalled',
}

export enum PwcCalculatorVersion {
	V4_IGNORE_RELATIVE = 'V4_IGNORE_RELATIVE',
	V4 = 'V4',
	V5 = 'V5',
}

export type ScoringFactor = {
    name: string
    factorId: string
    enabled?: boolean
}

export type ItemJiraRef = {
    url?: string
}

export type RankingAccessSettings = {
    allUsersAccessGroup: BacklogsAccessGroup
}

export enum BacklogsAccessGroup {
    none = "none",
    viewer = "viewer",
    rater = "rater",
    raterWithResults = "raterWithResults",
    editor = "editor"
}

export type BacklogMasterData = {
    backlogId: string
    name: string
    type: string
    hasJiraIntegration?:boolean
    accessSettings?: RankingAccessSettings
    quotas?: any
    quotasReached?: any
    meIsInParticipants?: boolean
    isDemo?: boolean
    showPromotions?: boolean
}

export interface RankingSharingData {
    url: string
    qrcodeUrl: string
    embedIframeHtmlCode?: string
}

export interface BacklogReactionsStats {
    itemsReactionsStats: Map<string, ReactionsStats>
}

export interface ReactionsStats {
    counts: Map<string, number>
}

export interface ParticipantsSegment {
    segmentId: string
    name: string
}

export interface ParticipantsSettings {
    segmentsEnabled: boolean
    segments: ParticipantsSegment[]
}

export interface UserSettings {
    segmentId?: string
}

export interface FactorACLItem {
    member: string
    factors: string[]
  }
  
  export enum FactorsACLMemberType {
    group = 'group',
    segment = 'segment',
  }