export enum RankingAnsweringStep {
    cover = 'cover',
    login = 'login',
    name = 'name',
    segment = 'segment',
    notEnoughItems = 'notEnoughItems',
    collectTogether = 'collectTogether',
    ratingHelp = 'ratingHelp',
    rating = 'rating',
    delimit = 'delimit',
    finished = 'finished',
}

export const RankingAnsweringEvent = {
    rankingRatingValuesDeleted: 'rankingRatingValuesDeleted',
    meUserSettingsItemsBlacklistChanged: 'rankingAnswering:meUserSettingsItemsBlacklistChanged',
}

export type RatingSettingsMy = {
    coverTitle: string
    coverSubtitle: string
}

export enum OptionDesign {
    textAndAssets = 'textAndAssets',
    imageCover = 'imageCover',
}

export enum OptionImageSize {
    standard = 'standard',
    large = 'large',
}

export enum OptionImageFit {
    standard = 'standard',
    cover = 'cover',
    contain = 'contain',
}

export enum RankingAnsweringMode {
    standard = 'standard',
    /**
     * In terminal mode, multiple persons can answer a ranking from the same browser.
     * The 'user' (not the same person) must get a new guest user assigned on beginning of answering the Ranking. 
     */
    terminal = 'terminal',
}

export interface RankingAnsweringState {
    guestIdentityRequired: boolean
    step?: RankingAnsweringStep
    mode: RankingAnsweringMode
    finished: any
}

export interface AnsweringPathSettingsStepInfo {
    step: RankingAnsweringStep
    enabled: boolean
}