import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';


export const useDebounced = (options) => {
  
  options = options || {}
  const { subscribe } = options

  const [waiting, setWaiting] = useState(false);

  /**
   * Debounces a function call
   *
   * @param {*} func
   * @param {*} timeout
   */
  const handleDebounced = (func, timeout, dependencies) => {
    
    const debounceHandler = useCallback(debounce((data)=>{
      func(data);
      if (subscribe) {
        setWaiting(false);
      }
    }, timeout), dependencies||[]);

    const onSubmit = useCallback(
        (data) => {
            if (subscribe) {
              setWaiting(true);
            }
            debounceHandler(data);
        },
        [debounceHandler]
    );

    return onSubmit;
  };

  return {handleDebounced, waiting};
};

/***
 * 
 * example that I used to build this:
 * 
 * 
/*   const onSubmit = (data) => {
    //console.log(data);
  } */

/*   const debounceHandler = useCallback(
    debounce((data) => {
    }, 1000),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      debounceHandler(data);
    },
    [debounceHandler]
  ); */
