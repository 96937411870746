import { Typography } from '@mui/material';

/**
 * Suited for the whole sreen are large area
 */
export const EmptyStateScreen = ({ heading, helperText }) => {
  return (
    <div>
        <div style={{marginBottom: '8px'}}>
          <Typography sx={{fontSize: '1.5rem'}}>{heading}</Typography>
        </div>
        <div>
          <Typography sx={{color: 'text.secondary'}}>{helperText}</Typography>
        </div>
    </div>
  )
}
